import {useState, useCallback, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import { TilesetViewer as Viewer } from "../../components/TilesetViewer";
import LoadSpinner from "../../components/LoadSpinner";
import { useTileset } from "../../hooks/useTileset";
import {
  Cartesian2,
  Cartesian3,
  Math as CesiumMath,
  HeightMeasurement,
  Cesium3DTileset,
  Viewer as CesiumViewer
} from "cesium";
import {AddModel} from "../../section/@tileset/AddModel";
import {Container, PlannerModelContainer} from "../TilesetBuilder/styles";
import {ModelCard} from "../../section/@tileset/ModelCard";
import {usePlannerTransformations, useSitePlanner} from "../../hooks";
import {PlannerActions} from "../../@types";
import authTokenModel from "../../model/AuthTokenModel";

export const TilesetViewer = () => {
  const [sitePlannerEnabled, setSitePlannerEnabled] = useState(localStorage.getItem("sitePlannerEnabled") === "true");
  const {
    isLoading,
    error,
    tilesetData,
    initialTilesetSettings,
    fetchTilesetData,
  } = useTileset();
  const { assessmentId } = useParams();
  const cesiumViewerRef = useRef<CesiumViewer>();
  const cesiumTilesetRef = useRef<Cesium3DTileset>();
  const [groundPointPickerActive, setGroundPointPickerActive] = useState(false);

  const {
    plannerModels,
    selectedPlannerModel,
    currentAction,
    addedPlannerModel,
    clearSelectedPlannerModel,
    addPlannerModel,
    zoomToPlannerModel,
    editPlannerModel,
    removePlannerModel,
    removedPlannerModel
  } = useSitePlanner();
  const {
    newPlannerModel,
    newPlannerPrimitive,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
    removePlannerModel: removePlannerModelTransformations,
  } = usePlannerTransformations(cesiumViewerRef.current);

  const updateCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
  };

  const updateCesiumTilesetRef = (tilesetRef: Cesium3DTileset) => {
    cesiumTilesetRef.current = tilesetRef;
    return tilesetRef;
  };

  useEffect(() => {
    if (addedPlannerModel && addedPlannerModel.ionId) {
      newPlannerModel(addedPlannerModel);
    } else if (addedPlannerModel && addedPlannerModel.shape) {
      newPlannerPrimitive(addedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedPlannerModel]);

  useEffect(() => {
    if (removedPlannerModel) {
      removePlannerModelTransformations(removedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedPlannerModel]);

  useEffect(() => {
    disableCurrentEditor();
    if (currentAction) {
      if (currentAction === PlannerActions.ZOOM) {
        zoomToSelectedPlannerModel();
      }
      if (currentAction === PlannerActions.EDIT) {
        editSelectedPlannerModel();
      }
    }
  }, [
    currentAction,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
  ]);

  const getTileset = useCallback(() => {
    if (!assessmentId || !authTokenModel.getAuthToken()) {
      window.location.reload();
      return;
    }
    fetchTilesetData(assessmentId);
  }, [assessmentId, fetchTilesetData]);

  useEffect(() => {
    getTileset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener("toggleSitePlanner", () => {
      setSitePlannerEnabled(localStorage.getItem("sitePlannerEnabled") === "true")
    });
    window.addEventListener("message", (e) => {
      if (e.data.event === "toggleGroundPointPicker") toggleGroundPointPicker();
    });
  }, []);

  useEffect(() => {
    if (!tilesetData) return;
    const viewer = cesiumViewerRef?.current;
    const heightMeasurement = viewer?.measure?.viewModel?._measurements?.find((x: any) => x.id === "terrainMeasurement");
    if (heightMeasurement) {
      heightMeasurement._height = tilesetData.tilesetGroundPointHeight || 0;
    }
  }, [tilesetData]);

  const toggleGroundPointPicker = () => {
    const newGPPA = !groundPointPickerActive;
    setGroundPointPickerActive(newGPPA);
    const viewer = cesiumViewerRef?.current;
    if (newGPPA) {
      viewer._container.addEventListener("click", handleGroundPointChange);
      viewer._container.style.cursor = "crosshair";
    } else {
      viewer._container.removeEventListener("click", handleGroundPointChange);
      viewer._container.style.cursor = "default";
    }
  };

  const handleGroundPointChange = useCallback((e: any) => {
    const viewer = cesiumViewerRef?.current;
    let position = new Cartesian3();
    position = HeightMeasurement._getWorldPosition(
      viewer.scene,
      new Cartesian2(e.clientX, e.clientY),
      position,
    );

    if (!position) return;
    const positionCartographic = viewer.scene.frameState.mapProjection.ellipsoid.cartesianToCartographic(position);
    let height = 0.0;
    if (viewer.scene.globe) {
      height = viewer.scene.globe.getHeight(positionCartographic) || 0.0;
    }
    height = positionCartographic.height - height;
    if (CesiumMath.equalsEpsilon(height, 0.0, CesiumMath.EPSILON3)) {
      height = 0.0;
    }
    if (isNaN(height)) return;

    const heightMeasurement = viewer?.measure?.viewModel?._measurements?.find((x: any) => x.id === "terrainMeasurement");
    if (heightMeasurement) {
      heightMeasurement._height = height;
    }
    viewer._container.removeEventListener("click", handleGroundPointChange);
    viewer._container.style.cursor = "default";
    setGroundPointPickerActive(false);

    window.parent.postMessage({ event: "savingGroundPoint", height }, "*");
  }, []);

  useEffect(() => {
    if (error && error.message && error.message !== "Unauthorized") {
      alert(error.message);
    }
  }, [error]);

  if (isLoading) {
    return <LoadSpinner />;
  }

  const isEditingPlannerModel = (plannerModelId: string) => {
    if (!selectedPlannerModel) return;
    return (
        plannerModelId === selectedPlannerModel.id &&
        currentAction === PlannerActions.EDIT
    );
  };

  if (tilesetData) {
    return <>
      <Viewer
        getCesiumViewerRef={updateCesiumViewerRef}
        getCesiumTilesetRef={updateCesiumTilesetRef}
        tilesetSettings={initialTilesetSettings}
      />
      {sitePlannerEnabled && <Container>
        <AddModel
            disablePlaceModelButton={!!addedPlannerModel}
            onAddModel={addPlannerModel}
        />
        <PlannerModelContainer>
          {plannerModels.map((plannerModel) => (
              <ModelCard
                  model={plannerModel}
                  key={plannerModel.id}
                  onZoomClick={zoomToPlannerModel}
                  onEditClick={editPlannerModel}
                  onFinishEditClick={clearSelectedPlannerModel}
                  onDeleteClick={removePlannerModel}
                  editing={isEditingPlannerModel(plannerModel.id)}
              />
          ))}
        </PlannerModelContainer>
      </Container>}
    </>;
  }
  return <></>;
};