import { put, takeLatest, call, select } from "redux-saga/effects";
import actions from "./actions";
import selectors from "./selectors";
import * as tilesetServices from "../../services/tileset";
import { TilesetDataActionTypes, TilesetDataAction } from "./types";
import { TilesetData } from "../../@types";
import { convertTilesetEditorParamsToTilesetData } from "../../utils";

export function* fetchTilesetData({ payload }: TilesetDataAction) {
  if (!payload) return;
  const { assessmentId } = payload;
  yield put(actions.setError(undefined));
  yield put(actions.setLoading(true));

  try {
    if (!assessmentId) throw new Error("No site assessment id was found");
    const response: TilesetData = yield call(
      tilesetServices.fetchTilesetData,
      assessmentId
    );
    yield put(actions.setTilesetData(response));
  } catch (e) {
    if (e.message !== "Unauthorized") e.message += ". Try refreshing the page";
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

const dispatchSuccessMessageToParentWindow = (assessmentId: string) => {
  const origin = "*";

  const event = {
    type: "SUCCESS_NEW_TILESET_SETTINGS",
    detail: {
      assessmentId,
    },
  };
  window.parent.postMessage(event, origin);
};

export function* saveNewTilesetEditorParams({ payload }: TilesetDataAction) {
  if (!payload) return;
  const { tilesetEditorParams } = payload;
  if (!tilesetEditorParams) return;
  const siteAssessmentId: string = yield select(selectors.getSiteAssessmentId);
  yield put(actions.setError(undefined));
  yield put(actions.setLoadingSaveNewTilesetEditorParams(true));
  try {
    let newTilesetData: TilesetData = yield call(
      convertTilesetEditorParamsToTilesetData,
      tilesetEditorParams
    );
    newTilesetData = { ...newTilesetData, siteAssessmentId };

    const tilesetData: TilesetData = yield select(selectors.getTilesetData);
    yield call(tilesetServices.saveTilesetEditorParams, newTilesetData, tilesetData);
    yield put(actions.setTilesetData({ ...tilesetData, ...newTilesetData }));
    yield call(dispatchSuccessMessageToParentWindow, siteAssessmentId);
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoadingSaveNewTilesetEditorParams(false));
  }
}

const sagas = [
  takeLatest(TilesetDataActionTypes.FETCH_TILESET_DATA, fetchTilesetData),
  takeLatest(
    TilesetDataActionTypes.SAVE_NEW_TILESET_EDITOR_PARAMS,
    saveNewTilesetEditorParams
  ),
];

export default sagas;
