const tenantApiV2Url = process.env.REACT_APP_V2_TENANT_API_ENDPOINT;
const staffApiV2Url = process.env.REACT_APP_V2_STAFF_API_ENDPOINT;

const endpoints = {
  getAssessment: `${staffApiV2Url}/api/assessment/`,
  cesiumLayers: `${staffApiV2Url}/api/cesium/layer-settings/`,
  getUserProfile: `${tenantApiV2Url}/api/user/profile/`,
  getAssetGroups: `${staffApiV2Url}/api/asset-group/`,
  getSiteAssetGroup: `${staffApiV2Url}/api/site/`
};

export default endpoints;
