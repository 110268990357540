import authTokenModel from "../model/AuthTokenModel";

const request = async (url: string, options: RequestInit) => {
  const sessionToken = authTokenModel.getAuthToken();
  const customOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      ...(sessionToken && { authorization: `Bearer ${sessionToken}` }),
    },
  };
  try {
    const response = await fetch(url, customOptions);

    if (response.status === 401 || response.status === 403) {
      window.location.reload();
      throw new Error("Unauthorized");
    }
    if (response.status !== 200) {
      const { detail, error } = await response.json();
      throw new Error(detail?.msg || error?.message || detail);
    }
    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export default request;
